//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-868:_540,_580,_4532,_1740,_8660,_408,_9904,_5032;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-868')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-868', "_540,_580,_4532,_1740,_8660,_408,_9904,_5032");
        }
      }catch (ex) {
        console.error(ex);
      }