import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

const AutoSyncKey = 'jce-racing';

const ignoreFields = bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetPanel')?.ignoreFields;

export default {
  ...bigcommerceSparkDefaults,
  includes: ['bigcommerce-spark'],
  fitmentSearch: {
    ...bigcommerceSparkDefaults.fitmentSearch,
    isVehicleSelectionIsolated: true,
  },
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter((w) => !['CategoryFacetPanel'].includes(w.name)),
    {
      name: 'CategoryFacetPanel',
      type: 'SearchPage',
      location: {
        firstChildOf: ['page-type-category', 'page-type-brand']
          .map((className) => `body.${className} #faceted-search-container`)
          .join(),
        class: 'cm_facet-panel_container',
      },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'WheelTireVehicleInfo',
      autoSyncVisualization: {
        apiKey: AutoSyncKey,
        config: {
          height: '100px',
        },
      },
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
